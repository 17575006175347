<template>
    <el-card shadow="never" :class="isClass">          
        <div slot="header" class="clearfix">            
            <span>Angajati</span>
            <span>
                <el-button type="warning" style="float: right;" size="mini" round @click="adauga">Adauga angajati</el-button>
            </span>
        </div>
        <!-- <pre>
        </pre> -->
        <el-row :gutter="15" v-show="selectedObject.Angajati.length > 0" style="margin-bottom: 20px;">
            <el-col :span="2">Tip angajat</el-col>
            <el-col :span="4">Angajat</el-col>
            <el-col :span="3">Data inceput</el-col>
            <el-col :span="3">Data sfarsit</el-col>
            <el-col :span="3">Numar ore / zi</el-col>
            <el-col :span="3">Numar maxim ore / luna</el-col>
            <el-col :span="2">Limita ore luna</el-col>                               
            <el-col :span="2">Vezi alocare</el-col>                               
            <el-col :span="2">Sterge</el-col>                               
        </el-row>

        <div class="angajati">   
            <el-form :model='selectedObject' ref='angajati_form' >
                <div v-for="(r,index) in selectedObject.Angajati" :key="r.key" >
                    <el-row :gutter="5">

                         <el-col :span='2'>
                            <el-select :prop="'Angajati.' + index + '.TipAngajat'" @change="goleste_angajatul(index)" v-model="r.TipAngajat" placeholder="Alege" class='full-width' filterable :rules="{required: true, message: 'Campul este obligatoriu'}">
                                <el-option v-for="item in OptiuniTipAngajat" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                         </el-col>
                    
                        <el-col :span='4'>
                            <el-form-item :prop="'Angajati.' + index + '.IdAngajat'" :rules="{required: true, message: 'Campul este obligatoriu'}">                                   
                                <SelectAngajat v-model="r.NumeAngajat" :IdAngajat.sync="r.IdAngajat" arataDoarAngajatiEligibiliProiecte :TipAngajati="r.TipAngajat"/>                       
                            </el-form-item>
                        </el-col>  
                        <el-col :span='3'>
                            <el-form-item :prop="'Angajati.' + index + '.DataInceput'" :rules="{required: true, message: 'Campul este obligatoriu'}">
                                <el-date-picker class='full-width' v-model="r.DataInceput" type="date" format="dd.MM.yyyy" value-format='yyyy-MM-dd' placeholder="Alege data" @change='dateModificate()' :picker-options="pickerOptions"> </el-date-picker>                              
                            </el-form-item>
                        </el-col>  
                        <el-col :span='3'>
                            <el-form-item :prop="'Angajati.' + index + '.DataSfarsit'" :rules="{required: true, message: 'Campul este obligatoriu'}">
                                <el-date-picker class='full-width'  v-model="r.DataSfarsit" type="date" format="dd.MM.yyyy" value-format='yyyy-MM-dd' placeholder="Alege data" @change='dateModificate' :picker-options="pickerOptions">  </el-date-picker >                                   
                            </el-form-item>
                        </el-col>   
                        <el-col :span='3' >
                            <el-form-item :prop="'Angajati.' + index + '.NumarOre'" :rules="{required: r.AreLimitareNumaxMaximLuna == '0', message: 'Campul este obligatoriu'}" >
                                <el-input-number :disabled="r.AreLimitareNumaxMaximLuna == '1'" class='full-width' v-model='r.NumarOre' :max="8" ></el-input-number>
                                
                            </el-form-item>
                        </el-col>                              
                        <el-col :span='3' >
                            <el-form-item :prop="'Angajati.' + index + '.MaxOreLuna'" :rules="{required: r.AreLimitareNumaxMaximLuna == '1', message: 'Campul este obligatoriu'}" >
                                <el-input-number class='full-width' v-model='r.MaxOreLuna' :disabled="r.AreLimitareNumaxMaximLuna == '0'"></el-input-number>
                            </el-form-item>
                        </el-col>               
                        <el-col :span='2'>
                            <el-form-item :prop="'Angajati.' + index + '.AreLimitareNumaxMaximLuna'">
                                <el-checkbox class='full-width' v-model='r.AreLimitareNumaxMaximLuna' true-label='1' false-label='0' border @change="stergeOreLuna(r, index)">DA </el-checkbox>                                                   
                            </el-form-item>
				        </el-col>
                         <el-col :span='2'>
                            <el-popover 
                                placement="top"
                                trigger="click">
                                <el-row>
                                    <span style="color:blue">Distributie ore alocate  / interval:</span>
                                </el-row>
                                <el-table :data="selectedObject.Angajati[index].AlocareMultipla">
                                    <el-table-column width="120" label="Data inceput">
                                        <template slot-scope="scope">
                                            {{ scope.row.DataInceput | format-date }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column width="120" label="Data sfarsit">
                                        <template slot-scope="scope">
                                            {{ scope.row.DataSfarsit | format-date }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column width="100" property="NumarOre" label="Ore"></el-table-column>
                                </el-table>
                                <el-button :disabled="r.IdAngajat == ''" @click="aloca_ore_angajat_interval(r, index)" class="full-width" type="primary" slot="reference" icon="el-icon-view"/>
                            </el-popover>
                            <!-- <pre>{{r}}</pre> -->
                        </el-col> 
                        <el-col :span="2">
                            <el-form-item>
                                <el-button type="danger" icon="el-icon-delete" class="full-width" @click.prevent="sterge(r)"></el-button>
                            </el-form-item>                                                                                                                                                                                                 
                        </el-col>             
                    </el-row>                          
                </div>  
            </el-form>
        </div>
                              
    </el-card>
</template>
<script>
import settings from "@/backend/LocalSettings"
import BasePage from '@/pages/BasePage'
import moment from 'moment';

export default {
    name: "AdaugareAngajati",
    extends: BasePage,
    props: {
        selectedObject: {
            type: Object,
            required: true
        },        
        isClass:{
            type: String,
            required: false,
            default () {
                return ""
            }
        },
        dataStartParinte:{
            type: String,
            required:false
        },
        dataSfarsitParinte:{
            type: String,
            required:false
        }
    },
    computed: {
        pickerOptions() {
            return {
                firstDayOfWeek: 1,
                disabledDate: (time) => {
                    return !moment(time.getTime()).isBetween(this.dataStartParinte, this.dataSfarsitParinte, null, '[]');
                }
            }
        },

    },
    
    data () {
        return {
            templateAngajat: { TipAngajat: 'Propriu', NumeAngajat: '', DataInceput:'', DataSfarsit:'', IdAngajat:'', AreLimitareNumaxMaximLuna:'0', MaxOreLuna:0, NumarOre:1, key: 1 },
            OptiuniTipAngajat: [{ value: 'Propriu', label: 'Propriu'}, { value: 'Cooptat',label: 'Cooptat' }]        
        }
    },
    methods: {
        stergeOreLuna(linie, index){
            if(this.selectedObject.Angajati[index].AreLimitareNumaxMaximLuna != 1)
                this.selectedObject.Angajati[index].MaxOreLuna   = 0;
            else
                this.selectedObject.Angajati[index].NumarOre     = 0;
        },        
        async aloca_ore_angajat_interval(linie, index){

            var raspuns  = await this.post("activitati/view_ore_angajat_interval", { rand: linie, selectedObject: this.selectedObject} );
            this.selectedObject.Angajati[index].AlocareMultipla = raspuns.Results;
            this.$forceUpdate();
        },
        goleste_angajatul (index) {
            this.selectedObject.Angajati[index].IdAngajat   = '';
            this.selectedObject.Angajati[index].NumeAngajat = '';
        },        
        adauga () {
            this.selectedObject.Angajati.push(JSON.parse(JSON.stringify(this.templateAngajat)))
            ++this.templateAngajat.key;

            let maxKey = this.selectedObject.Angajati.length
            this.schimbaNumarSelectii()
        },
        sterge (item) {
            var index = this.selectedObject.Angajati.indexOf(item)
            if (index != -1) {
                this.selectedObject.Angajati.splice(index, 1)
            }
            this.schimbaNumarSelectii()
        },
        schimbaNumarSelectii() {
            if (this.selectedObject.Angajati.length > 0) 
                this.selectedObject.Angajati.forEach((r,idx) => r.NumarSelectieAngajat = idx + 1)            
        },            
        dateModificate(){
            this.$emit('dateModificate')
        },
        // handleSelectAngajat(item) {
        //     //console.log(this.selectedObject);
        //     // this.$emit('selectie', item)
        //     // this.selectedObject.Angajati[index].IdAngajat = item.id
        // }
    },
    mounted: function () {
        
    }
}
</script>

<style lang="less">

    .gri{
        background-color: #DCDFE5;
    }

</style>
