<template>
    <el-dialog id="dialog" width="90%" :title="'Activitate ' + selectedObject.Nume" :visible.sync="showPopup" class="my-dialog-class"  append-to-body>
        <el-form label-position="top" :inline="false" :rules="rules" label-width="100%"  :model='selectedObject'  ref='my-form' @submit.prevent="save" v-loading="loadingVisible" >
            <el-row :gutter="15" >
                
                <el-col :md='4'>
                <!-- <pre>{{this.selectedObject}}</pre> -->
                    <el-form-item label='Cod' prop="Cod" >
                        <el-input  class='full-width' v-model='selectedObject.Cod' />
                    </el-form-item>
                </el-col>               
                <el-col :md='12'>
                    <el-form-item label='Nume' prop="Nume" >
                        <el-input  class='full-width' v-model='selectedObject.Nume' />
                    </el-form-item>
                </el-col>                 
                <el-col :span='8'>
                    <el-form-item label='Proiect' prop="IdProiect">
                        <SelectProiect v-model="selectedObject.NumeProiect" @selectie="handleSelectProiect" :IdProiect.sync="selectedObject.IdProiect" dezactivat />                        
                    </el-form-item>
                </el-col>      
                <el-col :md='8'>
                    <el-form-item label='Data inceput' prop="DataInceput">
                        <el-date-picker
                        class='full-width'
                        v-model="selectedObject.DataInceput"
                        :picker-options="{firstDayOfWeek: 1}"
                        type="date"
                        format="dd.MM.yyyy"
                        value-format='yyyy-MM-dd'
                        placeholder="Alege data" >                       
                        </el-date-picker>
                    </el-form-item>
                </el-col>  
                <el-col :md='8'>
                    <el-form-item label='Data sfarsit' prop="DataSfarsit">
                        <el-date-picker
                        class='full-width'
                        v-model="selectedObject.DataSfarsit"
                        :picker-options="{firstDayOfWeek: 1}"
                        type="date"
                        format="dd.MM.yyyy"
                        value-format='yyyy-MM-dd'
                        placeholder="Alege data" >                       
                        </el-date-picker>
                    </el-form-item>
                </el-col> 

                <el-col :md='4'>
					<el-form-item label='Are subactivitati' >
						<el-checkbox v-model='selectedObject.AreSubactivitati' true-label='1' false-label='0' border>DA </el-checkbox>                                                   
					</el-form-item>
				</el-col> 
                <el-col :md='4'>
					<el-form-item label='Are angajati' >
						<el-checkbox v-model='selectedObject.AreAngajati' true-label='1' false-label='0' border>DA </el-checkbox>                                                   
					</el-form-item>
				</el-col> 

                <el-col>
                    <AdaugareSubactivitati v-if="selectedObject.AreSubactivitati == '1'" ref="adaugareSubActivitati" :selectedObject.sync="selectedObject" :Info.sync="Info" @dateModificate="updateDateActivitati()"/>
                </el-col>
                <el-col>
                    <AtribuireAngajati v-if="selectedObject.AreAngajati == '1'" ref="adaugareAngajatiActivitate" :selectedObject.sync="selectedObject" :Info.sync="Info" :dataStartParinte="selectedObject.DataInceput" :dataSfarsitParinte="selectedObject.DataSfarsit" />
                </el-col>

                <!-- aaa1<pre>{{this.selectedObject}}</pre> -->
                
                       

            </el-row>
        </el-form>

        <span slot="footer" class="dialog-footer" >
            
            <el-button @click="showPopup=false"     > Renunta  </el-button>
            <el-button type="primary" @click="save" > Salveaza </el-button>
        </span>
    </el-dialog>
</template>

<script>
    import settings from "@/backend/LocalSettings";
    import BasePage from '@/pages/BasePage';
    import AdaugareSubactivitati from '@/components/AdaugareSubactivitati';   
    import AtribuireAngajati from '@/components/AtribuireAngajati'; 
    import moment from 'moment';

    export default {
        name: "Activitati_dialog",
        extends: BasePage,
        components: {
            AdaugareSubactivitati,
            AtribuireAngajati
        },
        watch:{
            'selectedObject.AreSubactivitati'(val){
                if(val == '1')
                    this.selectedObject.AreAngajati = '0'
            },
            'selectedObject.AreAngajati'(val){
                if(val == '1')
                    this.selectedObject.AreSubactivitati = '0'
            }
        },
        data () {
            return {
                baseUrl :'',
                showPopup: false,
                mode: 'add',
                selectedObjectTemplate: {Cod: '', Nume: '', DataInceput:'', DataSfarsit:'', IdProiect:'', NumeProiect:'', AreSubactivitati:'', Subactivitati:[], AreAngajati:'', Angajati:[]},    
                selectedObject:{},              
                Info:{ },
                                   
                rules: {
                    Cod:            [ { required: true, message: 'Campul este obligatoriu' } ], 
                    Nume:           [ { required: true, message: 'Campul este obligatoriu' } ], 
                    DataInceput:    [ { required: true, message: 'Campul este obligatoriu' } ], 
                    DataSfarsit:    [ { required: true, message: 'Campul este obligatoriu' } ], 
                    IdProiect:      [ { required: true, message: 'Campul este obligatoriu' } ], 
                }
            }
        },
        methods: {
            show_me: async function( id, proiect ) {
                this.selectedObject = JSON.parse(JSON.stringify(this.selectedObjectTemplate));
                this.showPopup      = true;
                if( id == null )
                {
                    this.mode = "add";
                    if(proiect){
                        this.selectedObject.IdProiect   = proiect.Id;
                        this.selectedObject.NumeProiect = proiect.Nume;
                        this.selectedObject.DataInceputProiect = proiect.DataInceput
                        this.selectedObject.DataSfarsitProiect = proiect.DataSfarsit
                    }
                }
                else
                {
                    this.mode           = "edit";
                    var result          = await this.post("activitati/get_info_item_dialog", { id: id } );
                    
                    this.selectedObject = result.Item;
                    this.selectedObject.DataInceputProiect = result.Item.Proiect.DataInceput
                    this.selectedObject.DataSfarsitProiect = result.Item.Proiect.DataSfarsit
                }
            },
            async get_info(){
                var response        = await this.post("activitati/get_info_for_dialog" );
            },
            save: async function() {

                this.$refs['my-form'].validate( async(valid) => {
                    if (valid)
                    {
                        var save_response = await this.post("activitati/save", { mode: this.mode, object: this.selectedObject } );

                        if(!save_response.Eroare){
                            this.$notify({
                                title: 'Succes',
                                message: 'Modificarile au fost salvate!',
                                type: 'success',
                            });
                            this.showPopup = false;
                            this.$emit("save");
                        } else {
                            this.$notify({
                                title: 'Eroare',
                                message: save_response.Eroare,
                                type: 'error',
                            });
                        }
                        
                    }
                });
            },
            updateDateActivitati(){  
                var subactivitati               = this.selectedObject.Subactivitati       
                
                var DataInceput = Math.min(...subactivitati.map(e => moment(e.DataInceput)))
                if(DataInceput)
                    this.selectedObject.DataInceput = moment(DataInceput).format("YYYY-MM-DD");
                
                var DataSfarsit = Math.max(...subactivitati.map(e => moment(e.DataSfarsit)))
                if(DataSfarsit)
                    this.selectedObject.DataSfarsit = moment(DataSfarsit).format("YYYY-MM-DD");

            },
            handleSelectProiect(item) {
                this.selectedObject.IdProiect = item.id
            }
        },
        mounted: function() {
            this.baseUrl = settings.BASE_URL;
            this.get_info();
        }
    };
</script>

<style lang="less" scoped>


    .full-width {
        width: 100%;
    }

</style>