<template>
    <el-dialog :title="'Proiect ' + selectedObject.Nume" :visible.sync="showPopup" class="my-dialog-class" width="96%">
        <el-form label-position="top" :inline="false" :rules="rules" label-width="100%"  :model='selectedObject'  ref='my-form' @submit.prevent="save" v-loading="loadingVisible" >
            <el-row :gutter="15" >
                
                <el-col :md='8'>
                    <el-form-item label='Nume' prop="Nume" >
                        <el-input  class='full-width' v-model='selectedObject.Nume' />
                    </el-form-item>
                </el-col>
                <el-col :span='8'>
                    <el-form-item label='Manager' prop="IdManager">
                        <SelectAngajat v-model="selectedObject.Manager" @selectie="handleSelectAngajat" :IdAngajat.sync="selectedObject.IdManager" arataDoarAngajatiEligibiliProiecte />                      
                    </el-form-item>
                </el-col> 
                <el-col :md='8'>
                    <el-form-item label='Cod MySMIS' prop="Cod">
                        <el-input  class='full-width' v-model='selectedObject.Cod' />
                    </el-form-item>
                </el-col>
                <el-col :md='8'>
                    <el-col style="margin-top:9px" :span="4">
                        <span class="tip">Tip</span>
                    </el-col>  

                    <el-tooltip placement="top" :content="content">
                        <el-button style="margin-top:7px;" size="mini" plain round type="info"> i </el-button>
                    </el-tooltip>               
                    
                    <el-form-item style="margin-top:23px" prop="TipProiect" >
                        <el-select class='full-width' v-model='selectedObject.TipProiect' filterable>
                            <el-option label='POCA' value='POCA'></el-option>
                            <el-option label='POIM' value='POIM'></el-option>
                            <el-option label='Norvegiene' value='Norvegiene'></el-option>
                            <el-option label='Life' value='Life'></el-option>
                            <el-option label='PODD' value='PODD'></el-option>                             
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :md='8'>
                    <el-form-item label='Data inceput' prop="DataInceput">
                        <el-date-picker
                        class='full-width'
                        v-model="selectedObject.DataInceput"
                        :picker-options="{firstDayOfWeek: 1}"
                        type="date"
                        format="dd.MM.yyyy"
                        value-format='yyyy-MM-dd'
                        placeholder="Alege data">
                        </el-date-picker>
                    </el-form-item>
                </el-col>  
                <el-col :md='8'>
                    <el-form-item label='Data sfarsit' prop="DataSfarsit">
                        <el-date-picker
                        class='full-width'  
                        v-model="selectedObject.DataSfarsit"
                        :picker-options="{firstDayOfWeek: 1}"
                        type="date"
                        format="dd.MM.yyyy"
                        value-format='yyyy-MM-dd'
                        placeholder="Alege data">
                        </el-date-picker>
                    </el-form-item>
                </el-col>            
            </el-row>
        </el-form>

        <!-- <div v-if="mode=='edit'"> -->
        <el-card shadow="never">          
            <div slot="header" class="clearfix">            
                <span>Activitati</span> <span style="color:red"></span>
                <span>
                    <el-button v-if="selectedObject.Id == undefined" type="warning" style="float: right;" size="mini" round @click="save_and_show_dialog_activitate(null, selectedObject)">Salveaza si adauga activitate</el-button>
                    <el-button v-else type="warning" style="float: right;" size="mini" round @click="show_dialog_activitate(null, selectedObject)">Adauga activitate</el-button>
                </span>
            </div>
            
            <!-- <el-row>
                <span>
                    <el-button type="warning" style="float: right;" size="mini" round @click="show_dialog_activitate(null, selectedObject)">Adauga activitate</el-button>
                </span>
            </el-row> -->
            <el-table :data="Results" v-if="Results.length > 0" class="tabel-activitati">
                <el-table-column prop='Cod' label='Cod activitate' width='118 px'></el-table-column>
                <el-table-column prop='Nume' label='Nume activitate'></el-table-column>
                <el-table-column prop='DataInceput' label='Data inceput' width="110px">
                    <template slot-scope="scope" >
                        <div >{{scope.row.DataInceput | format-date}}</div>
                    </template>
                </el-table-column>
                <el-table-column prop='DataSfarsit' label='Data sfarsit' width="110px">
                    <template slot-scope="scope" >
                        <div >{{scope.row.DataSfarsit | format-date}}</div>
                    </template>
                </el-table-column>
                <el-table-column fixed="right" label="Actiuni" width="90px" >
                    <template slot-scope="scope" >

                        <el-tooltip content="Modificare">
                            <el-button size="mini" type="primary" icon="el-icon-edit"  circle @click="show_dialog_activitate(scope.row.Id)" />
                        </el-tooltip>

                        <el-tooltip content="Sterge" >
                            <el-button size="mini" type="danger" icon="el-icon-delete" circle @click="delete_activitate(scope.row)" />
                        </el-tooltip>

                    </template>
                </el-table-column>
            </el-table>
            <!-- <el-pagination @size-change="refresh_info" @current-change= "refresh_info" :page-size.sync="PaginationInfo.PerPage" :current-page.sync="PaginationInfo.Page" :total="PaginationInfo.RowCount" layout="pager" /> -->
        </el-card>
        <!-- </div> -->

        <span slot="footer" class="dialog-footer" >
            <el-button @click="showPopup=false"     > Renunta  </el-button>
            <el-button type="primary" @click="save" > Salveaza </el-button>
        </span>
        <Activitati-dialog ref='dlg' @save="refresh_info()" />
    </el-dialog>
</template>

<script>
    import settings from "@/backend/LocalSettings";
    import BasePage from '@/pages/BasePage';
    import Activitati_dialog from '@/pages/proiecte/Activitati_dialog.vue';

    export default {
        name: "Proiecte_dialog",
        extends: BasePage,
        components: {
            'Activitati-dialog': Activitati_dialog,
        },
        data () {
            return {
                Results: [],
                baseUrl :'',
                showPopup: false,
                mode: 'add',
                selectedObjectTemplate: {Manager: '', Nume: '' , Cod:'', TipProiect:'', DataInceput:'', DataSfarsit:''},    
                selectedObject:{},    
                ttt: 1,          
                Info:{ },
                                   
                rules: {
                    Nume:           [ { required: true, message: 'Campul este obligatoriu' } ], 
                    IdManager:      [ { required: true, message: 'Campul este obligatoriu' } ], 
                    Cod:            [ { required: true, message: 'Campul este obligatoriu' } ], 
                    TipProiect:     [ { required: true, message: 'Campul este obligatoriu' } ], 
                    DataInceput:    [ { required: true, message: 'Campul este obligatoriu' } ], 
                    DataSfarsit:    [ { required: true, message: 'Campul este obligatoriu' } ], 
                },
                PaginationInfo: { Page: 1, PerPage: 10, RowCount: 0, PageSizes: [5, 10, 15, 30, 100] },

                content: 'Pentru alte tipuri de proiecte nespecificate este necesara updatarea din perspectiva programare la noile reguli conform noilor cerinte'
            }
        },
        methods: {
            async refresh_info(){
                var response        = await this.post("proiecte/get_activitati", { IdProiect: this.selectedObject.Id, OrderBy: this.OrderBy, PaginationInfo: this.PaginationInfo } );
                this.Results        = response.Results;
                this.PaginationInfo = response.PaginationInfo;
                //
                this.select_menu_item('activitati');
            },
            show_me: async function( id ) {
                this.selectedObject = JSON.parse(JSON.stringify(this.selectedObjectTemplate));
                this.showPopup      = true;
                if( id == null )
                {
                    this.mode = "add";
                    this.Results = [];
                }
                else
                {
                    this.mode             = "edit";
                    var            result = await this.post("proiecte/get_info_item_dialog", { id: id } );
                    this.selectedObject   = result.Item;
                    this.refresh_info();
                }
            },

            save: async function() {

                this.$refs['my-form'].validate( async(valid) => {
                    if (valid)
                    {
                        await this.post("proiecte/save", { mode: this.mode, object: this.selectedObject } );
                        this.showPopup = false;
                        this.$emit("save");
                    }
                });
            },
            handleSelectAngajat(item) {
                this.selectedObject.IdManager = item.id
            },
            async delete_activitate( item ){
                var confirm =  await this.$confirm(`Sunteti sigur ?`, 'Warning');
                if( confirm )
                {
                    await this.post("proiecte/delete_activitate", { id: item.Id } );
                    this.refresh_info();
                }
            },
            show_dialog_activitate(id, proiect){
                this.$refs.dlg.show_me(id, proiect);
            },
            async save_and_show_dialog_activitate(id, proiect){
               this.$refs['my-form'].validate( async(valid) => {
                    if (valid)
                    {
                        var response = await this.post("proiecte/save", { mode: this.mode, object: this.selectedObject } );
                        this.selectedObject.Id = response.Proiect.Id;
                        this.$refs.dlg.show_me(id, proiect);
                        this.refresh_info();
                        this.mode='edit';
                    }
                });
            },
        },
        mounted: function() {
            this.baseUrl = settings.BASE_URL;
        }
    };
</script>

<style lang="less" scoped>
    .full-width {
        width: 100%;
    }
    .tip:before {        
        content: '*';
        color: #F56C6C;
        margin-right: 4px;
    }
    .tabel-activitati{
        margin-bottom:10px;
    }

</style>