<template>
    <base-page>
        <titlu-pagina Titlu="Proiecte" @on_add_clicked="show_dialog()" :AdaugaVisible='true' />
        <el-card style='margin:5px 0px 5px 0px'>
            <div slot="header" class="clearfix">
                <strong> Filtre </strong>
            </div>
            <div class="filtre">
                <el-form @submit.prevent.native='refresh_info()'>
                    <el-row :gutter="20">
                        
                        <el-col :span='4'>
                            <el-form-item label='Nume' >
                                <el-input v-model='Filters.Nume' />
                            </el-form-item>
                        </el-col>                         
                        <el-col :span='4'>
                            <el-form-item label='Manager' >
                                <SelectAngajat v-model="Filters.Manager" @selectie="handleSelectAngajat" :IdManager.sync="Filters.IdManager" arataDoarAngajatiEligibiliProiecte />
                            </el-form-item>
                        </el-col> 
                        <el-col :span='4'>
                            <el-form-item label='Cod' >
                                <el-input v-model='Filters.Cod' />
                            </el-form-item>
                        </el-col> 
                        <el-col :span='4'>
                            <el-form-item label='Tip' prop="TipProiect" >
                                <el-select class='full-width' v-model='Filters.TipProiect' filterable>
                                    <el-option label='POCA' value='POCA'></el-option>
                                    <el-option label='POIM' value='POIM'></el-option>
                                    <el-option label='Norvegiene' value='Norvegiene'></el-option>
                                    <el-option label='Life' value='Life'></el-option>                            
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span='4'>
                            <el-form-item label='Data inceput' >
                                <el-input v-model='Filters.DataInceput' />
                            </el-form-item>
                        </el-col> 
                        <el-col :span='4'>
                            <el-form-item label='Data sfarsit' >
                                <el-input v-model='Filters.DataSfarsit' />
                            </el-form-item>
                        </el-col>                        
                        <el-col :md='24' >
                            <el-button @click="reset()" > Reset  </el-button>
                            <el-button type='primary' native-type='submit' @click='refresh_info()'> Aplica </el-button>
                        </el-col>

                    </el-row>
                </el-form>
                </div>
        </el-card>

        <el-table :data="Results" >
            <el-table-column prop='Nume' label='Nume'></el-table-column>
            <el-table-column prop='Manager' label='Manager'></el-table-column>
            <el-table-column prop='Cod' label='Cod MySMIS'></el-table-column>
            <el-table-column prop='TipProiect' label='Tip'></el-table-column>
            <el-table-column prop='DataInceput' label='Data inceput'></el-table-column>
            <el-table-column prop='DataSfarsit' label='Data sfarsit'></el-table-column>
            <el-table-column fixed="right" label="Actiuni" width="200px" >
                <template slot-scope="scope" >

                    <el-tooltip content="Modificare">
                        <el-button type="primary" icon="el-icon-edit"  circle @click="show_dialog(scope.row.Id)" />
                    </el-tooltip>

                    <el-tooltip content="Sterge" >
                            <el-button type="danger" icon="el-icon-delete" circle @click="delete_item(scope.row)" />
                    </el-tooltip>

                    <!-- <el-tooltip content="Gantt" >
                            <el-button type="success" icon="el-icon-view" circle @click="open_gantt(scope.row.Id)" />
                    </el-tooltip> -->

                </template>
            </el-table-column>
        </el-table>
        <el-pagination @size-change="refresh_info" @current-change= "refresh_info" :page-size.sync="PaginationInfo.PerPage" :current-page.sync="PaginationInfo.Page" :total="PaginationInfo.RowCount" layout="pager" />
        
        <Proiecte-dialog ref='dlg' @save="refresh_info()" />
        <Proiect-gantt-dialog ref='gantt_dialog'/>
    
    </base-page>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import Proiecte_dialog from '@/pages/proiecte/Proiecte_dialog.vue';
import TitluPagina from '@/widgets/TitluPagina';
import Proiect_gantt_dialog from './Proiect_gantt_dialog';

export default {
    name: "Proiecte",
    extends: BasePage,
    components: {
        'base-page': BasePage,
        'Proiecte-dialog': Proiecte_dialog,
        'titlu-pagina': TitluPagina,
        'Proiect-gantt-dialog':Proiect_gantt_dialog
    },
    data () {
        return {
            Results: [],
            base_url: '',
            Info: {
                            },
            Filters: {Nume: '' , Cod:'', TipProiect:'', DataInceput:'',DataSfarsit:'', IdManager: ''},
                
            OrderBy: { },
            PaginationInfo: { Page: 1, PerPage: 50, RowCount: 0, PageSizes: [10, 25, 50, 100, 200] },
        }
    },
    methods: {

        async get_info(){
            var response = await this.post("proiecte/get_info" );
            this.refresh_info();
        },

        async refresh_info(){
            var response        = await this.post("proiecte/index", { Filters: this.Filters, OrderBy: this.OrderBy, PaginationInfo: this.PaginationInfo } );
            this.Results        = response.Results;
            this.PaginationInfo = response.PaginationInfo;
            //
            this.select_menu_item('proiecte');
        },
        reset(){
            this.Filters = {Nume: '' , Cod:'', TipProiect:'', DataInceput:'', DataSfarsit:'', IdManager:''};
            this.refresh_info();
        },

        async delete_item( item ){
            var confirm =  await this.$confirm(`Sunteti sigur ?`, 'Warning');
            if( confirm )
            {
                await this.post("proiecte/delete_item", { id: item.Id } );
                this.refresh_info();
            }
        },

        show_dialog(id){
            this.$refs.dlg.show_me(id);
        },
        handleSelectAngajat(item) {
            this.Filters.IdManager = item.id
        },
        open_gantt(id){
            this.$refs['gantt_dialog'].show_me(id)
        }
    },
    mounted(){
        this.base_url = settings.BASE_URL;
        this.get_info();
    }
};
</script>

<style lang="less" scoped>

    .top50{
        margin-top: 20px;
    }

    .filtre{
        .el-input-number
        {
            width: 100% !important;
        }
    }

</style>
