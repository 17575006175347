<template>
    <el-dialog title="Proiect" :visible.sync="showPopup" class="my-dialog-class" width="100%">

        <GraficGantt />
        <!-- <el-row >
            <el-col v-for="(item,index_an) in Results" :key="index_an" :span="24/Results.length" >
                <el-col class="clasa-an">{{item.an}}</el-col>
                    <el-col class="clasa-luna" v-for="(item_luna,index_luna) in item.luni" :key="index_luna" :span="2"> 
                        <span >{{item_luna.luna}}</span>                       
                        <el-col :class="item_luna.valoare ? 'background-rosu' : 'background-normal' "> <span>{{item_luna.valoare}}</span> </el-col>
                    </el-col>
            </el-col>    
        </el-row>   -->
                
            <!-- <table style="width:100%">
                <tr>
                    <td v-for="(item,index_an) in Results" :key="index_an" style="text-align: center;">
                        <table style="width:100%">
                            <tr>
                                <th style="text-align: center;height: 50px;" colspan="12">{{item.an}}</th>
                            </tr>
                            <tr>
                                <td style="text-align: center;" v-for="(item_luna,index_luna) in item.luni" :key="index_luna">
                                    <table style="width:100%;border-collapse: collapse;">
                                        <tr>
                                            <th style="text-align: center;height: 50px;" > {{item_luna.luna}}  </th>
                                        </tr>
                                        <tr>
                                            <td style="width: 10%;" :class="item_luna.valoare ? 'background-rosu' : 'background-normal' "> <span>{{item_luna.valoare}}</span> </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            
                        </table>
                        
                    </td>
                </tr>                                
            </table> -->
            
           
    </el-dialog>
</template>

<script>
    import settings from "@/backend/LocalSettings";
    import BasePage from '@/pages/BasePage';
    import GraficGantt from '@/components/Grafic_gantt';
    

    export default {
        name: "Proiect_gantt_dialog",
        extends: BasePage,
        components:{
            GraficGantt
        },
        data () {
            return {
                baseUrl :'',
                showPopup: false,
                messages: [],
    
                filters: {
                    toPercent (val) {
                        if(!val) return '0'
                            return Math.round((+val) * 100)
                    },
                    niceDate (obj){
                        return '${obj.getFullYear()} / ${obj.getMonth()} / ${obj.getDate()}'
                    }
                } ,
                Results:[]      
            }
        },
        methods: {
            async show_me( id ) {
                this.showPopup  = true;   
                var result      = await this.post("proiecte/get_raport_gantt", {id_proiect:id})                 
                this.Results    = result                
            },

            returnCellStyle(params){
                const clasa = ".el-table_2_column_8_column_9_column_10";
                // var x = document.getElementsByClassName(".el-table_2_column_8_column_9_column_10").style.backgroundColor='red';
                // console.log(x);
                // console.log('raspuns',params.column.id);
                // var val = document.getElementsByClassName(params.column.id);
                // console.log(this.$refs.valueColumn);

                // if (column.property === 'rate') {
                //     const val = row[column.property];
                //     if (val > 0 ) {
                //         return 'greenClass'
                //     } else {
                //         return 'redClass'
                //     }
                // }
                return 'redClass';
            },

            addMessage (message) {
                this.messages.unshift(message)
                if (this.messages.length > 40) {
                    this.messages.pop()
                }
            },

            logTaskUpdate (id, mode, task) {
                let text = (task && task.text ? ` (${task.text})`: '')
                let message = `Task ${mode}: ${id} ${text}`
                this.addMessage(message)
            },

            logLinkUpdate (id, mode, link) {
                let message = `Link ${mode}: ${id}`
                if (link) {
                    message += ` ( source: ${link.source}, target: ${link.target} )`
                }
                this.addMessage(message)
            },
            selectTask: function(task){
                this.selectedTask = task
            }
  
        },
        mounted: function() {
            this.baseUrl = settings.BASE_URL;
            // var width = this.$el.querySelector('.el-dialog__header').offsetWidth            
            
            // document.getElementsByClassName(".el-table_2_column_8_column_9_column_10").style.backgroundColor='red';
            // document.getElementsByClassName(".el-table_2_column_8_column_9_column_10").add("mystyle")
        }
    };
</script>

<style lang="less" >
    // .el-table_2_column_8_column_9_column_11  {
    //     background-color: blue;
    // }
    .clasa-an{
        text-align: center;
        height:40px;
        border: solid .1px #dcdfe5;
    }
    .clasa-luna{
        text-align: center;
        border: solid .1px #dcdfe5;
    }
    .background-rosu{
        background-color: red;
        margin-top:20px;
        span{
            opacity: 0
        }             
    }    
    .background-normal{
        margin-top:20px;
        span{
            opacity: 0
        } 
    }
    .mica {
        width: 8%;
    }    
    .mare {
        width: 10%;
    }
    .full-width {
        width: 100%;
    }

    .tip:before {        
        content: '*';
        color: #F56C6C;
        margin-right: 4px;
    }

    .gantt-selected-info {

        border-bottom: 1px solid #cecece;
        box-sizing: border-box;
        font-family: Geneva, Arial, Helvetica, sans-serif;
        height: 50%;
        line-height: 28px;
        padding: 10px;
    }

    .gantt-selected-info h2 {
        border-bottom: 1px solid #cecece;
    }

    .select-task-prompt h2{
        color: #d9d9d9;
    }

    .container {
        height: 100%;
        width: 100%;
    }
    .left-container {
        overflow: hidden;
        position: relative;
        height: 100%;
    }
    .right-container {
        border-right: 1px solid #cecece;
        float: right;
        height: 100%;
        width: 340px;
        box-shadow: 0 0 5px 2px #aaa;
        position: relative;
        z-index:2;
    }
    .gantt-messages {
        list-style-type: none;
        height: 50%;
        margin: 0;
        overflow-x: hidden;
        overflow-y: auto;
        padding-left: 5px;
    }
    .gantt-messages > .gantt-message {
        background-color: #f4f4f4;
        box-shadow:inset 5px 0 #d69000;
        font-family: Geneva, Arial, Helvetica, sans-serif;
        font-size: 14px;
        margin: 5px 0;
        padding: 8px 0 8px 10px;
    }

</style>