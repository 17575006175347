<template>
    <el-card shadow="never">          
        <div slot="header" class="clearfix">            
            <span>Subactivitati</span> <span style="color:red"></span>
            <span>
                <el-button type="warning" style="float: right;" size="mini" round @click="adauga">Adauga subactivitate</el-button>
            </span>
        </div>

        <el-row :gutter="15" v-show="selectedObject.Subactivitati.length > 0" style="margin-bottom: 20px;">
            <el-col :span="2">Cod</el-col>
            <el-col :span="10">Nume</el-col>
            <el-col :span="4">Data inceput</el-col>
            <el-col :span="4">Data sfarsit</el-col>
            <el-col :span="2">Are <p>angajati</p></el-col>
            <el-col :span="2">Sterge <br>subactivitate</el-col>                               
        </el-row>

        <div class="subactivitati">      

            <div v-for="(r,index) in selectedObject.Subactivitati" :key="r.key" >
                <el-row :gutter="15">
                
                    <el-col :span='2'>
                        <el-form-item :prop="'Subactivitati.' + index + '.Cod'" :rules="{required: true, message: 'Campul este obligatoriu'}">                                   
                            <el-input v-model='r.Cod' class='full-width' >  </el-input>                          
                        </el-form-item>
                    </el-col>                      
                    <el-col :span='10'>
                        <el-form-item :prop="'Subactivitati.' + index + '.Nume'" :rules="{required: true, message: 'Campul este obligatoriu'}">                                   
                            <el-input v-model='r.Nume' class='full-width' >  </el-input>                          
                        </el-form-item>
                    </el-col>  
                    <el-col :span='4'>
                        <el-form-item :prop="'Subactivitati.' + index + '.DataInceput'" :rules="{required: true, message: 'Campul este obligatoriu'}">
                            <el-date-picker
                            class='full-width'
                            v-model="r.DataInceput"
                            :picker-options="pickerOptionsDataInceput"
                            type="date"
                            format="dd.MM.yyyy"
                            value-format='yyyy-MM-dd'
                            placeholder="Alege data"
                            @change='dateModificate'>
                            </el-date-picker>
                        </el-form-item>
                    </el-col>  
                    <el-col :span='4'>
                        <el-form-item :prop="'Subactivitati.' + index + '.DataSfarsit'" :rules="{required: true, message: 'Campul este obligatoriu'}">
                            <el-date-picker
                            class='full-width'
                            v-model="r.DataSfarsit"
                            :picker-options="pickerOptionsDataInceput"
                            type="date"
                            format="dd.MM.yyyy"
                            value-format='yyyy-MM-dd'
                            placeholder="Alege data"
                            @change='dateModificate'>
                            </el-date-picker >                           
                        </el-form-item>
                    </el-col>  
                    <el-col :md='2'>
                        <el-form-item>
                            <el-checkbox v-model='r.AreAngajati' true-label='1' false-label='0' border>DA </el-checkbox>                                                   
                        </el-form-item>
                    </el-col>                 
                    <el-col :span="2">
                        <el-form-item>
                            <el-button type="danger" icon="el-icon-delete" @click.prevent="sterge(r)"></el-button>
                        </el-form-item>                                                                                                                                                                                                 
                    </el-col>  

                    <el-col :span="24" :offset="0" class="div-angajati">                        
                        <AtribuireAngajati v-if="r.AreAngajati == '1'" ref="adaugareAngajatiSubactivitate" :selectedObject.sync="selectedObject.Subactivitati[index]" :dataStartParinte="r.DataInceput" :dataSfarsitParinte="r.DataSfarsit" isClass="gri" />
                    </el-col>   
                </el-row>                                        
            </div>  

        </div>
                              
    </el-card>
</template>
<script>
import settings from "@/backend/LocalSettings"
import BasePage from '@/pages/BasePage'
import AtribuireAngajati from '@/components/AtribuireAngajati';
import moment from 'moment';

export default {
    name: "AdaugareSubactivitati",
    extends: BasePage,
    components: {
            AtribuireAngajati
        },
    props: {
        selectedObject: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            templateSubactivitate: { Cod: '', Nume: '', DataInceput:'', DataSfarsit:'', AreAngajati:'', Angajati:[], key: 1, IdProiect:'' },

            rules: {
                IdAngajat:          [ { required: true, message: 'Campul este obligatoriu' } ], 
                IdActivitate:       [ { required: true, message: 'Campul este obligatoriu' } ], 
                IdSubactivitate:    [ { required: true, message: 'Campul este obligatoriu' } ], 
                NumarOre:           [ { required: true, message: 'Campul este obligatoriu' } ],                    
                DataAdaugare:       [ { required: true, message: 'Campul este obligatoriu' } ],                    
            }
        }
    },
    methods: {
        adauga () {   
            this.templateSubactivitate.IdProiect = 'sss';         
            this.selectedObject.Subactivitati.push(JSON.parse(JSON.stringify(this.templateSubactivitate)))
            ++this.templateSubactivitate.key;

            let maxKey = this.selectedObject.Subactivitati.length
            this.schimbaNumarSelectii()

            // console.log(this.selectedObject)
            
        },
        sterge (item) {
            var index = this.selectedObject.Subactivitati.indexOf(item)
            if (index != -1) {
                this.selectedObject.Subactivitati.splice(index, 1)
            }
            this.schimbaNumarSelectii()
        },
        schimbaNumarSelectii() {
            if (this.selectedObject.Subactivitati.length > 0) 
                this.selectedObject.Subactivitati.forEach((r,idx) => r.NumarSelectie = idx + 1)            
        },
        dateModificate(){
            this.$emit('dateModificate')
            // console.log(this.selectedObject.Subactivitati)
        },
        handleSelectAngajat(item) {
            this.$emit('selectie', item)
        }
    },
    mounted: function () {
        // console.log('componenta subactivitati', this.selectedObject);

    },
    computed: {
        pickerOptionsDataInceput() {
            return {
                firstDayOfWeek: 1,
                disabledDate: (time) => {
                    return !moment(time.getTime()).isBetween(this.selectedObject.DataInceputProiect, this.selectedObject.DataSfarsitProiect, null, '[]');
                }
            }
        }

    },
}
</script>

<style lang="less">
    // .subactivitati {
        // height: 200px;
        // overflow: scroll;
    // }
    .div-angajati{        
        margin-bottom:10px;
        // padding: 20px 40px !important;
    }

</style>
